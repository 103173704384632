.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  padding-top: 9rem;
  align-items: center;
}
.ttl {
  font-size: 50px;
  margin-bottom: 1.5rem;
  color: #101319;
}
.txt {
  color: #96999f;
  font-size: 19px;
  line-height: 1.6;
  max-width: 480px;
}
.loanCalc {
  border: 1px solid #1b1f27;
  border-radius: 20px;
  padding: 8px;
  max-width: 460px;
  margin-left: auto;
  width: 100%;

  form {
    padding: 1.5rem 2rem;
  }
}
.loanInfo {
  background: #0d0d0d;
  padding: 1.5rem 2rem;
  border-radius: 20px;

  &__ttl {
    color: #96999f;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1rem;
  }

  &__amt {
    color: #ffffff;
    font-size: 44px;
    text-align: center;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.loanBreakdown {
  border: 1px solid #535353;
  border-radius: 6px;
  padding: 0 1rem;
  margin-top: 2rem;

  div {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
    padding: 1rem 0;

    &:first-of-type {
      border-bottom: 1px solid #535353;
    }

    p:last-of-type {
    }
  }
}
.calcBtn {
  color: #fff;
  border-radius: 6px;
  background: #3bb81d;
  height: 48px;
  padding: 6px;
  width: 100%;
  border: none;
  margin-top: 1rem;
}
@media screen and (max-width: 800px) {
  .container {
    padding-top: 6.5rem;
  }
  .ttl {
    font-size: 30px;
  }
  .txt {
    font-size: 14px;
  }
  .loanInfo {
    &__ttl {
      font-size: 14px;
    }

    &__amt {
      font-size: 36px;
    }
  }
  .loanBreakdown {
    div {
      font-size: 14px;
    }
  }
}
