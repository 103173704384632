.heroBg {
  background: linear-gradient(359deg, #fff 20.68%, #d6ffcc 142.84%), #fff;
}
.hero {
  text-align: center;
  padding-top: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ttl {
  color: #101319;
  font-size: 52px;
  font-style: normal;
  font-weight: 600;
  max-width: 700px;
  line-height: 1.3;
  letter-spacing: -1.12px;
  margin-bottom: 1.5rem;
}
.txt {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4px;
  max-width: 650px;
  margin-bottom: 1.5rem;
  line-height: 1.6;
}
.btns {
  gap: 1rem;
  display: flex;
  margin-bottom: 1rem;
}
.img {
  width: 100%;
  max-width: 717px;
  margin-left: 7rem;
}

@media screen and (max-width: 800px) {
  .ttl {
    font-size: 40px;
  }
  .txt {
    font-size: 16px;
  }
  .img {
    margin-left: 0;
  }
}
@media screen and (max-width: 600px) {
  .ttl {
    font-size: 32px;
    margin-bottom: 1rem;
  }
  .txt {
    font-size: 14px;
    margin-bottom: 1rem;
  }
  .btns {
    svg {
      width: 120px;
    }
  }
}
