.inputParent {
  margin-bottom: 1.2rem;

  label {
    display: block;
    color: #14151A;
    margin-bottom: 6px;
    font-size: 13px;
    font-weight: 500;
  }
}
.inputWrap {
  display: flex;
  border-radius: 6px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 8px 16px;
  gap: 6px;
  height: 40px;

  // input {
  //   width: 100%;
  //   outline: none;
  //   border: none;
  // }
}

.errorMessage {
  color: red;
  font-size: 11px;
  margin-top: 8px;
}
